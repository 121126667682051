import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"; // <-- Add this line
import "./User.css"; // Ensure the CSS for loading animation is included.
import Img from "../../Assets/images/compressimg/RoseNoirTee.webp";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(""); // Reset any previous error message

    try {
      // Sending login credentials to backend API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/signin`,
        {
          email: formData.email,
          password: formData.password,
        }
      );

      if (response.status === 200) {
        const { token, user } = response.data;

        // Extract only required user fields
        const filteredUser = {
          _id: user._id,
          name: user.name,
          email: user.email,
          phoneNumber: user.phoneNumber,
          isLoggedIn: user.isLoggedIn,
        };

        // Store token and filtered user in local storage
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(filteredUser));

        navigate("/"); // Redirect to the dashboard or home page
        window.location.reload();
      }
    } catch (error) {
      // Handle error
      setErrorMessage(
        error.response?.data?.message || "Login failed. Please try again."
      );
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="min-w-screen flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: "1000px" }}
      >
        <div className="md:flex w-full">
          <div
            className="w-full md:w-1/2 py-10 px-5 md:px-10 bg-white"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-center mb-5">
              <h1 className="font-bold text-3xl text-gray-900">SIGN IN</h1>
              <p>Enter your information to Login</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-5">
                  <label htmlFor="email" className="text-xs font-semibold px-1">
                    Email
                  </label>
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <i className="mdi mdi-email-outline text-gray-400 text-lg"></i>
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="w-full -ml-10 pl-4 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black"
                      placeholder="johnsmith@example.com"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-12">
                  <label
                    htmlFor="password"
                    className="text-xs font-semibold px-1"
                  >
                    Password
                  </label>
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <i className="mdi mdi-lock-outline text-gray-400 text-lg"></i>
                    </div>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="w-full -ml-10 pl-4 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black"
                      placeholder="************"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {errorMessage && (
                <p className="text-red-500 text-xs">{errorMessage}</p>
              )}

              <div className="flex -mx-3">
                <div className="w-full px-3 mb-5">
                  <button
                    type="submit"
                    className="block w-full max-w-xs mx-auto  from-gray-800 via-gray-900 to-black hover:bg-black-500 text-white rounded-lg px-3 py-3 font-semibold"
                    style={{ backgroundColor: "#191919" }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <span className="dots-container">
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                      </span>
                    ) : (
                      "SIGN IN"
                    )}
                  </button>
                </div>
              </div>
            </form>

            <div className="text-center mt-5">
              <p className=" text-gray-600" style={{ fontSize: "larger" }}>
                Don't have an account?{" "}
                <Link to="/register" className="text-blue-500">
                  Sign Up
                </Link>
              </p>
            </div>

            {/* Forgot Password Link */}
            <div className="text-center mt-2">
              <Link
                to="/forgot-password"
                className="text-blue-500 "
                style={{ fontSize: "larger" }}
              >
                Forgot Password?
              </Link>
            </div>
          </div>

          <div className="hidden md:block w-1/2 bg-gradient-to-r from-gray-800 via-gray-900 to-black ">
            <img src={Img} alt="Login" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

import React, { useState, useRef, useEffect } from "react";
import { Menu, X } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from "../../Assets/Logos/Avant Divine Website logo.png";
import "./Navbar.css";
import CartSidebar from "./CartSidebar";
import UserMenu from "./UserMenu";
import { ShoppingBag } from "lucide-react";

export default function Navbar(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [CartCount, setCartCount] = useState();

  const [isCollactionOpen, setIsCollactionOpen] = useState(false); // For Collaction submenu
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [storedUser, setStoredUser] = useState(null);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const userMenuRef = useRef(null); // Ref for the user menu

  useEffect(() => {
    console.log(`I am here ==>${props.cartCount}`);
    // Close user menu if clicked outside
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchCartCount();
  }, []);

  const fetchCartCount = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));

    if (storedUserData && storedUserData.isLoggedIn) {
      setIsLoggedIn(storedUserData.isLoggedIn);
      setStoredUser(storedUserData);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/cart/user/${storedUserData._id}`
        );

        // Check if the response is valid and contains cart data
        if (response.data.status && response.data.data.cart) {
          setCartCount(response.data.data.cart.items.length);
          setCartItems(response.data.data.cart.items); // Assuming you want to store the cart items as well
          setCartTotal(
            response.data.data.cart.items.reduce(
              (sum, item) => sum + item.productId.price * item.quantity,
              0
            )
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("Cart not found, initializing empty cart.");
          // If the cart is not found, set an empty cart
          setCartCount(0);
          setCartItems([]);
          setCartTotal(0);
        } else {
          console.error("Error fetching cart data:", error);
        }
      }
    }
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleCart = () => setIsCartOpen(!isCartOpen);
  const toggleUserMenu = () => setIsUserMenuOpen(!isUserMenuOpen);
  const toggleCollaction = () => setIsCollactionOpen(!isCollactionOpen); // Toggle Collaction submenu

  const handleLogout = async () => {
    if (storedUser && storedUser.email) {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        email: storedUser.email,
      });
      localStorage.clear();
      setIsUserMenuOpen(false);
      // navigate("/");
      window.location.href = "/";
    }
  };

  const handleCheckout = () => {
    setIsCartOpen(false);
    navigate("/checkoutpage");
  };

  const incrementQuantity = async (productId) => {
    const item = cartItems.find((item) => item.productId._id === productId);
    const updatedQuantity = item.quantity + 1;
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/cart/update/${productId}`,
      { quantity: updatedQuantity }
    );
    if (response.data.status) {
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.productId._id === productId
            ? { ...item, quantity: updatedQuantity }
            : item
        )
      );
      setCartTotal(
        cartItems.reduce(
          (sum, item) => sum + item.productId.price * item.quantity,
          0
        )
      );
    }
  };

  const decrementQuantity = async (productId) => {
    const item = cartItems.find((item) => item.productId._id === productId);
    if (item.quantity > 1) {
      const updatedQuantity = item.quantity - 1;
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/cart/update/${productId}`,
        { quantity: updatedQuantity }
      );
      if (response.data.status) {
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.productId._id === productId
              ? { ...item, quantity: updatedQuantity }
              : item
          )
        );
        setCartTotal(
          cartItems.reduce(
            (sum, item) => sum + item.productId.price * item.quantity,
            0
          )
        );
      }
    }
  };

  return (
    <>
      <div className="h-[64px] w-full"></div>
      <nav className="fixed top-0 left-0 w-full bg-white shadow-md z-50 h-[64px]">
        <div className="flex items-center justify-between px-4 py-4 h-full">
          <button
            ref={menuRef}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>

          <div className="absolute left-1/2 transform -translate-x-1/2">
            <Link to="/" className="block" onClick={() => setIsMenuOpen(false)}>
              <img src={Logo} alt="AVANT DIVINE" className="w-auto h-20" />
            </Link>
          </div>

          <div className="flex items-center gap-0 sm:gap-4">
            <a href="/callus">
              <button className="hidden md:block hover:text-gray-600 transition-colors">
                Call Us
              </button>
            </a>

            <button
              className="relative p-2 hover:bg-gray-100 rounded-lg transition-colors"
              aria-label="Shopping cart"
              onClick={() => (window.location.href = "/cart")}
            >
              <ShoppingBag className="h-6 w-6" />
              <span className="absolute -top-1 -right-1 h-5 w-5 text-xs flex items-center justify-center bg-black text-white rounded-full">
                {props.cartCount}
              </span>
            </button>

            <UserMenu
              ref={userMenuRef}
              isUserMenuOpen={isUserMenuOpen}
              toggleUserMenu={toggleUserMenu}
              isLoggedIn={isLoggedIn}
              storedUser={storedUser}
              handleLogin={() => setIsLoggedIn(true)}
              handleLogout={handleLogout}
            />
          </div>
        </div>
      </nav>

      {isMenuOpen && (
        <div className="fixed top-0 left-0 w-[240px] bg-white shadow-xl z-40 h-full p-4">
          <ul className="space-y-4" style={{ marginTop: "3.5rem" }}>
            <li>
              <Link
                to="/"
                className="block px-4 py-2 text-lg hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                Home
              </Link>
            </li>
            {/* Collaction menu item */}
            <li>
              <button
                onClick={toggleCollaction}
                className="block px-4 py-2 text-lg hover:bg-gray-100 w-full text-left flex items-center justify-between"
              >
                Collection
                {/* Arrow icon */}
                <span
                  className={`transition-transform duration-200 ${
                    isCollactionOpen ? "rotate-180" : ""
                  }`}
                >
                  ▼
                </span>
              </button>

              {/* Collaction submenu (Genesis) */}
              {isCollactionOpen && (
                <ul className="pl-6 mt-2 space-y-2">
                  <li>
                    <Link
                      to="/shop"
                      className="block px-4 py-2 text-lg hover:bg-gray-100"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Genesis
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link
                to="/aboutus"
                className="block px-4 py-2 text-lg hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/callus"
                className="block px-4 py-2 text-lg hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      )}

      <CartSidebar
        cartItems={cartItems}
        cartTotal={cartTotal}
        toggleCart={toggleCart}
        isCartOpen={isCartOpen}
        incrementQuantity={incrementQuantity}
        decrementQuantity={decrementQuantity}
        handleCheckout={handleCheckout}
      />
    </>
  );
}

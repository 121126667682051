import React from "react";

export default function AvantCreditsPage() {
  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold mb-4 mt-8">AVANT CREDITS</h1>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="grid sm:grid-cols-2 gap-8">
          <div>
            <h2 className="text-xl font-semibold mb-4">Available Credits</h2>
            <div className="text-4xl font-bold mb-2">1,250</div>
            <p className="text-gray-600">
              Credits can be used towards future purchases
            </p>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-4">Credit History</h2>
            <div className="space-y-4">
              {[1, 2, 3].map((item) => (
                <div
                  key={item}
                  className="flex justify-between pb-4 border-b border-gray-100"
                >
                  <div>
                    <div className="font-medium">Order #30004</div>
                    <div className="text-sm text-gray-500">May 31, 2019</div>
                  </div>
                  <div className="font-medium text-green-600">+50 credits</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

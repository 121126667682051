import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "./User.css";
import Img from "../../Assets/images/compressimg/RoseNoirTee.webp";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { email, otp } = location.state || {};

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newPassword || !confirmPassword) {
      setError("Both fields are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setError("");
    setLoading(true);

    const data = {
      email: email,
      otp: otp,
      newPassword: newPassword,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/reset-password`,
        data
      );

      if (response.status === 200) {
        setSuccess("Password has been reset successfully.");
        setTimeout(() => navigate("/login"), 2000);
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error resetting password:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-w-screen flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: "1000px" }}
      >
        <div className="md:flex w-full">
          <div
            className="w-full md:w-1/2 py-10 px-5 md:px-10 bg-white"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-center ">
              <h1 className="font-bold text-3xl " style={{ color: "#191919" }}>
                RESET PASSWORD
              </h1>
              <p>Enter a new password and confirm it</p>
            </div>
            <div>
              <div className="flex -mx-3">
                <div className="w-full px-3  mt-5">
                  <label
                    htmlFor="newPassword"
                    className="text-xs font-semibold px-1"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    id="newPassword"
                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black"
                    placeholder="Enter your new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-5 mt-5">
                  <label
                    htmlFor="confirmPassword"
                    className="text-xs font-semibold px-1"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black"
                    placeholder="Confirm your new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              {error && <p className="text-red-500 text-xs">{error}</p>}
              {success && <p className="text-green-500 text-xs">{success}</p>}
              <div className="flex -mx-3">
                <div className="w-full px-3 mt-5">
                  <button
                    onClick={handleSubmit}
                    className="block w-full max-w-xs mx-auto  from-gray-800  to-black text-white rounded-lg px-3 py-3 font-semibold"
                    style={{ backgroundColor: "#191919" }}
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="dots-container">
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                      </span>
                    ) : (
                      "RESET PASSWORD"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block w-1/2 bg-gradient-to-r from-gray-800 via-gray-900 to-black ">
            <img src={Img} alt="Reset Password" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./User.css";
import Img from "../../Assets/images/compressimg/RoseNoirTee.webp";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [otpStatus, setOtpStatus] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Email is required.");
      return;
    }

    setError("");
    setOtpStatus("Sending OTP");

    const data = {
      email: email,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        data
      );

      if (response.status === 200) {
        console.log("OTP sent successfully:", response.data);
        setOtpStatus("OTP Sent");
        navigate("/recover-otp", { state: { email: email } });
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error sending OTP:", error);
      setOtpStatus("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-w-screen flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: "1000px" }}
      >
        <div className="md:flex w-full">
          <div
            className="w-full md:w-1/2 py-10 px-5 md:px-10 bg-white"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-center ">
              <h1 className="font-bold text-3xl text-gray-900">
                ONE TIME PASSWORD
              </h1>
              <p>Enter your email to receive an OTP</p>
            </div>
            <div>
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-5 mt-5">
                  <label htmlFor="" className="text-xs font-semibold px-1">
                    Email
                  </label>
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <i className="mdi mdi-email-outline text-gray-400 text-lg"></i>
                    </div>
                    <input
                      type="email"
                      className="w-full -ml-10 pl-4 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black"
                      placeholder="johnsmith@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {error && <p className="text-red-500 text-xs">{error}</p>}
              <div className="flex -mx-3">
                <div className="w-full px-3 mt-5">
                  <button
                    onClick={handleSubmit}
                    className="block w-full max-w-xs mx-auto  from-gray-800 via-gray-900 to-black hover:bg-black-500 text-white rounded-lg px-3 py-3 font-semibold"
                    style={{ backgroundColor: "#191919" }}
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="dots-container">
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                      </span>
                    ) : otpStatus === "Sending OTP" ? (
                      "Sending OTP "
                    ) : otpStatus === "OTP Sent" ? (
                      "OTP Sent"
                    ) : (
                      "SEND OTP"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block w-1/2 bg-gradient-to-r from-gray-800 via-gray-900 to-black ">
            <img src={Img} alt="OTP" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

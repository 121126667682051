import React from "react";

const ForgotPasswordForm = ({ onSubmit, onBackToLogin }) => {
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("email");
    onSubmit(email);
  };

  return (
    <div className="w-full">
      <h2 className="text-3xl font-bold text-center mb-8">Forgot Password</h2>

      <p className="text-center text-gray-600 mb-8">
        Enter your email address and we'll send you a verification code to reset
        your password
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <input
            type="email"
            name="email"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="Email"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-rose-500 text-white py-2 rounded-md hover:bg-rose-600 transition-colors"
        >
          Send Reset Code
        </button>
      </form>

      <p className="mt-4 text-center">
        Remember your password?{" "}
        <button
          onClick={onBackToLogin}
          className="text-rose-500 hover:text-rose-600"
        >
          Back to login
        </button>
      </p>
    </div>
  );
};

export default ForgotPasswordForm;

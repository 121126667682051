import React, { useState, useEffect } from "react";

export default function PaymentMethodsPage() {
  // Load cards from localStorage or set default values if not present
  const loadCardsFromLocalStorage = () => {
    const storedCards = JSON.parse(localStorage.getItem(""));
    return storedCards
      ? storedCards
      : [
          // {
          //   id: "1",
          //   type: "credit",
          //   last4: "4278",
          //   name: "Ben Cline",
          //   expiry: "02/24",
          //   network: "visa",
          // },
          // {
          //   id: "2",
          //   type: "debit",
          //   last4: "3792",
          //   name: "Ben Cline",
          //   expiry: "03/25",
          //   network: "mastercard",
          // },
          // {
          //   id: "3",
          //   type: "credit",
          //   last4: "1901",
          //   name: "Ben Cline",
          //   expiry: "11/23",
          //   network: "amex",
          // },
        ];
  };

  const [cards, setCards] = useState(loadCardsFromLocalStorage());
  const [editingCard, setEditingCard] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [isAddingCard, setIsAddingCard] = useState(false); // Track if we're adding a new card
  const [newCard, setNewCard] = useState({
    name: "",
    type: "credit",
    last4: "",
    expiry: "",
    network: "visa",
  });

  // Function to save cards to localStorage
  const saveCardsToLocalStorage = (cards) => {
    localStorage.setItem("cards", JSON.stringify(cards));
  };

  // Update localStorage whenever cards change
  useEffect(() => {
    saveCardsToLocalStorage(cards);
  }, [cards]);

  const handleDelete = (cardId) => {
    const updatedCards = cards.filter((card) => card.id !== cardId);
    setCards(updatedCards);
    setShowDeleteConfirm(null);
  };

  const getCardIcon = (network) => {
    switch (network) {
      case "visa":
        return <div className="text-white text-sm font-bold">VISA</div>;
      case "mastercard":
        return <div className="text-white text-sm font-bold">MC</div>;
      case "amex":
        return <div className="text-white text-sm font-bold">AMEX</div>;
      default:
        return null;
    }
  };

  // Handle adding a new card
  const handleAddCard = (e) => {
    e.preventDefault();

    const newCardId = Date.now().toString(); // Generate a unique ID based on current timestamp
    const updatedCards = [...cards, { ...newCard, id: newCardId }];
    setCards(updatedCards);

    // Reset the form and close the "Add Card" form
    setNewCard({
      name: "",
      type: "credit",
      last4: "",
      expiry: "",
      network: "visa",
    });
    setIsAddingCard(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold mb-12">PAYMENT METHODS</h1>

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Credit Cards */}
        {cards.map((card) => (
          <div key={card.id} className="relative">
            <div className="bg-gray-900 text-white p-6 rounded-lg">
              <div className="flex justify-between items-start mb-8">
                <div className="flex items-center gap-2">
                  <div className="w-12 h-8 bg-gray-800 rounded flex items-center justify-center">
                    {getCardIcon(card.network)}
                  </div>
                  <span className="text-xs uppercase">{card.type}</span>
                </div>
                <div className="relative">
                  <button
                    onClick={() =>
                      setEditingCard(editingCard === card.id ? null : card.id)
                    }
                    className="text-gray-400 hover:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                      />
                    </svg>
                  </button>

                  {/* Edit/Delete Menu */}
                  {editingCard === card.id && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                      <button
                        onClick={() => {
                          // Handle edit functionality
                          setEditingCard(null);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Edit Card
                      </button>
                      <button
                        onClick={() => {
                          setShowDeleteConfirm(card.id);
                          setEditingCard(null);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                      >
                        Delete Card
                      </button>
                    </div>
                  )}

                  {/* Delete Confirmation */}
                  {showDeleteConfirm === card.id && (
                    <div className="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg p-4 z-10">
                      <p className="text-sm text-gray-700 mb-4">
                        Are you sure you want to delete this card?
                      </p>
                      <div className="flex justify-end gap-2">
                        <button
                          onClick={() => setShowDeleteConfirm(null)}
                          className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => handleDelete(card.id)}
                          className="px-3 py-1 text-sm text-white bg-red-600 rounded hover:bg-red-700"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="font-mono mb-4">•••• •••• •••• {card.last4}</div>
              <div className="text-sm text-gray-400">
                <div>{card.name}</div>
                <div>{card.expiry}</div>
              </div>
            </div>
          </div>
        ))}

        {/* Add Card Button */}
        <button
          onClick={() => setIsAddingCard(true)}
          className="border-2 border-dashed border-gray-200 rounded-lg p-6 text-center hover:border-gray-300"
        >
          <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </div>
          <div className="text-gray-600">ADD CARD</div>
        </button>

        {/* Add New Card Form */}
        {isAddingCard && (
          <div className="col-span-2 lg:col-span-3">
            <form
              onSubmit={handleAddCard}
              className="bg-gray-900 text-white p-6 rounded-lg"
            >
              <h2 className="text-xl font-semibold mb-4">Add New Card</h2>
              <div className="mb-4">
                <label className="block text-sm text-gray-300">Card Name</label>
                <input
                  type="text"
                  value={newCard.name}
                  onChange={(e) =>
                    setNewCard({ ...newCard, name: e.target.value })
                  }
                  className="w-full p-2 bg-gray-800 text-white rounded-lg"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-gray-300">Card Type</label>
                <select
                  value={newCard.type}
                  onChange={(e) =>
                    setNewCard({ ...newCard, type: e.target.value })
                  }
                  className="w-full p-2 bg-gray-800 text-white rounded-lg"
                >
                  <option value="credit">Credit</option>
                  <option value="debit">Debit</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm text-gray-300">
                  Expiry Date
                </label>
                <input
                  type="text"
                  value={newCard.expiry}
                  onChange={(e) =>
                    setNewCard({ ...newCard, expiry: e.target.value })
                  }
                  className="w-full p-2 bg-gray-800 text-white rounded-lg"
                  placeholder="MM/YY"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-gray-300">
                  Last 4 Digits
                </label>
                <input
                  type="text"
                  value={newCard.last4}
                  onChange={(e) =>
                    setNewCard({ ...newCard, last4: e.target.value })
                  }
                  className="w-full p-2 bg-gray-800 text-white rounded-lg"
                  maxLength="4"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-gray-300">
                  Card Network
                </label>
                <select
                  value={newCard.network}
                  onChange={(e) =>
                    setNewCard({ ...newCard, network: e.target.value })
                  }
                  className="w-full p-2 bg-gray-800 text-white rounded-lg"
                >
                  <option value="visa">Visa</option>
                  <option value="mastercard">MasterCard</option>
                  <option value="amex">AMEX</option>
                </select>
              </div>
              <button
                type="submit"
                className="w-full p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Add Card
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

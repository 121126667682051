import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Reels1 from "../../Assets/Videos/AVANTDIVINE2_2-ezgif.com-video-to-gif-converter.gif";
import Reels2 from "../../Assets/Videos/AVANTDIVINE3_1-ezgif.com-video-to-gif-converter.gif";
import Reels3 from "../../Assets/Videos/AVANTDIVINE5Final-ezgif.com-video-to-gif-converter.gif";
import Reels4 from "../../Assets/Videos/AVANTDIVNE4-ezgif.com-video-to-gif-converter.gif";

export default function ReelsSection() {
  const reels = [
    {
      id: 1,
      thumbnail: Reels1,
      title: "Summer Fashion Trends",
      views: "1.2M",
      user: {
        name: "FashionGuru",
        avatar: "/placeholder.svg",
      },
      isVideo: false, // Changed to false for image usage
      link: "/shop", // Added link
    },
    {
      id: 2,
      thumbnail: Reels2,
      title: "Streetwear Lookbook",
      views: "890K",
      user: {
        name: "UrbanStyler",
        avatar: "/placeholder.svg",
      },
      isVideo: false, // Changed to false for image usage
      link: "/shop", // Added link
    },
    {
      id: 3,
      thumbnail: Reels3,
      title: "Accessories Must-Haves",
      views: "650K",
      user: {
        name: "AccessoryQueen",
        avatar: "/placeholder.svg",
      },
      isVideo: false, // Changed to false for image usage
      link: "/shop", // Added link
    },
    {
      id: 4,
      thumbnail: Reels4,
      title: "Sustainable Fashion",
      views: "1.5M",
      user: {
        name: "EcoChic",
        avatar: "/placeholder.svg",
      },
      isVideo: false, // Changed to false for image usage
      link: "/shop", // Added link
    },
  ];

  return (
    <section className="bg-gray-100 py-2 sm:py-12 w-full">
      <div className="mx-auto px-4 w-full">
        <h2 className="text-3xl font-bold mb-2 sm:mb-8 text-center">
          Divine Spotlight
        </h2>

        {/* Grid layout for larger screens */}
        <div className="hidden sm:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {reels.map((reel) => (
            <Link
              key={reel.id}
              to={reel.link} // Use `to` instead of `href`
              className="bg-white rounded-lg overflow-hidden shadow-md w-full"
            >
              <div className="relative aspect-[9/16] w-full">
                <img
                  src={reel.thumbnail}
                  alt={reel.title}
                  className="object-cover w-full h-full"
                />
              </div>
            </Link>
          ))}
        </div>

        {/* Horizontal scroll container for mobile view */}
        <div className="flex sm:hidden overflow-x-scroll scrollbar-hide space-x-4">
          {reels.map((reel) => (
            <Link
              key={reel.id}
              to={reel.link} // Use `to` instead of `href`
              className="bg-white rounded-lg overflow-hidden shadow-md min-w-[80%] sm:min-w-0"
            >
              <div className="relative aspect-[9/16] w-full">
                <img
                  src={reel.thumbnail}
                  alt={reel.title}
                  className="object-cover w-full h-full"
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { Link } from "react-router-dom"; // Import the Link component

const CODOrderSuccess = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
        <svg
          className="w-16 h-16 text-green-500 mx-auto mb-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          Order Successfully Placed!
        </h1>
        <p className="text-xl text-gray-600 mb-6">
          Your Cash on Delivery (COD) order has been confirmed.
        </p>
        <p className="text-gray-600 mb-8">
          Thank you for your order. We'll send you a confirmation email with
          your order details.
        </p>

        {/* Link component used to navigate to the homepage */}
        <Link
          to="/" // Navigate to the homepage
          className="bg-blue-500 text-white font-semibold px-6 py-2 rounded hover:bg-blue-600 transition duration-300"
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default CODOrderSuccess;

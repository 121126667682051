import React, { useState } from "react";
import axios from "axios";
import { MapPin } from "lucide-react";
import "./GetAddress.css";

const getAddressFromCoordinates = async (lat, lon, setAddress) => {
  try {
    const response = await axios.get(
      "https://nominatim.openstreetmap.org/reverse",
      {
        params: {
          lat: lat,
          lon: lon,
          format: "json",
          addressdetails: 1,
          language: "en",
        },
      }
    );

    if (response.data && response.data.address) {
      const address = response.data.address;
      console.log("Formatted Address:", response.data.display_name);
      console.log("Address Components:", address);

      // Use the address components to fill the form
      setAddress((prevAddress) => ({
        ...prevAddress,
        addressLine: `${address.city_district || ""}, ${address.state_district || ""}`,
        city: address.city || address.town || "N/A",
        state: address.state || "N/A",
        postalCode: address.postcode || "N/A",
        country: address.country || "N/A",
      }));
    } else {
      console.log("No results found for these coordinates.");
    }
  } catch (error) {
    console.error("Error fetching geocode details:", error.message);
  }
};

const LocationButton = ({ setAddress }) => {
  const [loading, setLoading] = useState(false);

  const getLocation = async () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          await getAddressFromCoordinates(latitude, longitude, setAddress);
          setLoading(false);
        },
        () => {
          alert("Unable to retrieve your location.");
          setLoading(false);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  return (
    
    <button
      onClick={getLocation}
      className="location-button px-4 py-2 bg-black text-white hover:bg-gray-800 rounded-md flex items-center justify-center font-bold"
      disabled={loading}
    >
      {/* Only show the icon if not loading */}
      {!loading && <MapPin className="mr-2 font-bold" size={19} />}
      {loading ? (
        <div className="dots-container">
          <div className="dot">•</div>
          <div className="dot">•</div>
          <div className="dot">•</div>
        </div>
      ) : (
        "FIND ME"
      )}
    </button>
  );
};

export default LocationButton;

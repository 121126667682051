import React, { useState } from "react";

export default function AccountSettingsPage() {
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [alternatePhone, setAlternatePhone] = useState("");

  const handleGenderChange = (e) => setGender(e.target.value);
  const handleBirthdayChange = (e) => setBirthday(e.target.value);
  const handleAlternatePhoneChange = (e) => setAlternatePhone(e.target.value);

  return (
    <div className="max-w-4xl mx-auto mt-8 sm:px-6 lg:px-8">
      <h1 className="text-4xl font-bold mb-4 text-center">ACCOUNT SETTINGS</h1>

      {/* Account Form */}
      <div className="mb-12">
        {/* <h2 className="text-xl mb-6">Account</h2> */}
        <form
          className="space-y-6 max-w-xl mx-auto"
          style={{ padding: "10px" }}
        >
          <div className="grid sm:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm text-gray-500 mb-1">Email</label>
              <input
                type="email"
                defaultValue="ben@dailyinteractive.com"
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-500 mb-1">Phone</label>
              <input
                type="tel"
                defaultValue="+1 801 641 0924"
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
              />
            </div>
          </div>

          {/* Alternate Mobile Number */}
          <div>
            <label className="block text-sm text-gray-500 mb-1">
              Alternate Mobile Number
            </label>
            <input
              type="tel"
              value={alternatePhone}
              onChange={handleAlternatePhoneChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
              placeholder="+1 234 567 8901"
            />
          </div>

          <div className="grid sm:grid-cols-2 gap-6">
            {/* Gender Selection */}
            <div>
              <label className="block text-sm text-gray-500 mb-1">Gender</label>
              <select
                value={gender}
                onChange={handleGenderChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            {/* Birthday */}
            <div>
              <label className="block text-sm text-gray-500 mb-1">
                Birthday
              </label>
              <input
                type="date"
                value={birthday}
                onChange={handleBirthdayChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
              />
            </div>
          </div>

          <button className="w-full bg-black text-white py-3 rounded-md hover:bg-gray-800 transition-colors duration-200">
            SAVE CHANGES
          </button>
        </form>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import OrderDetails from "./OrderDetails";
import Wishlist from "./Wishlist";
import AvantCredits from "./AvantCredits";
import PaymentSettings from "./PaymentSettings";
import AddressDetails from "./AddressDetails";
import EditProfile from "./EditProfile";

export default function Profile() {
  const [activeTab, setActiveTab] = useState("orders");

  const tabs = [
    { id: "orders", label: "Order Details" },
    { id: "wishlist", label: "Wishlist" },
    { id: "credits", label: "Avant Credits" },
    { id: "payment", label: "Payment Settings" },
    { id: "address", label: "Address Details" },
    { id: "edit", label: "Edit Profile" },
  ];

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">My Profile</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex flex-wrap">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === tab.id
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  } mr-8 mb-2 sm:mb-0`}
                >
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
          <div className="p-4 sm:p-6">
            {activeTab === "orders" && <OrderDetails />}
            {activeTab === "wishlist" && <Wishlist />}
            {activeTab === "credits" && <AvantCredits />}
            {activeTab === "payment" && <PaymentSettings />}
            {activeTab === "address" && <AddressDetails />}
            {activeTab === "edit" && <EditProfile />}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import axios from "axios";

const OtpVerify = ({
  phoneNumber,
  email,
  password,
  onVerificationComplete,
  onBackClick,
  type,
}) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState("");

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = otp.join("");

    if (otpCode.length !== 6) {
      setOtpError("Please enter a valid OTP.");
      return;
    }

    setLoading(true);
    setOtpError(""); 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify-otp`,
        {
          email: email, 
          otp: otpCode,
          password: password,
        }
      );

      if (response.status === 200) {
        onVerificationComplete();
        console(`after verify otp ==> ${onVerificationComplete()}`)
      } else {
        setOtpError(response.data.message || "Verification failed!");
      }
    } catch (error) {
      setOtpError("An error occurred during verification.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/resend-otp`,
        { email: email }
      );
      alert("OTP resent successfully!");
    } catch (error) {
      alert("Failed to resend OTP.");
    }
  };

  return (
    <div className="w-full">
      <h2 className="text-3xl font-bold text-center mb-8">
        {type === "signup" ? "Verify Phone Number" : "Verify Email"}
      </h2>

      <p className="text-center text-gray-600 mb-8">
        We've sent a verification code to
        <br />
        <span className="font-medium">
          {type === "signup" ? phoneNumber : email}
        </span>
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex justify-center space-x-4">
          {otp.map((digit, i) => (
            <input
              key={i}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleOtpChange(e, i)}
              className="w-12 h-12 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
              required
            />
          ))}
        </div>

        {otpError && <p className="text-red-500 text-center">{otpError}</p>}

        <button
          type="submit"
          disabled={loading}
          className={`w-full bg-rose-500 text-white py-2 rounded-md hover:bg-rose-600 transition-colors ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? "Verifying..." : "Verify"}
        </button>
      </form>

      <div className="mt-6 text-center space-y-4">
        <p className="text-gray-600">
          Didn't receive the code?{" "}
          <button
            onClick={handleResendOtp}
            className="text-rose-500 hover:text-rose-600"
          >
            Resend
          </button>
        </p>
        <button
          onClick={onBackClick}
          className="text-rose-500 hover:text-rose-600"
        >
          {type === "signup" ? "Change phone number" : "Change email"}
        </button>
      </div>
    </div>
  );
};

export default OtpVerify;

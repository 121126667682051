import React, { useState } from "react";

export default function AvantCredits() {
  const [credits, setCredits] = useState(500);

  const addCredits = () => {
    setCredits(credits + 100);
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Avant Credits</h2>
      <div className="bg-gray-100 p-6 rounded-lg">
        <p className="text-4xl font-bold text-indigo-600 mb-2">{credits}</p>
        <p className="text-gray-600 mb-4">Available credits</p>
        <button
          onClick={addCredits}
          className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600 transition-colors"
        >
          Add 100 Credits
        </button>
      </div>
    </div>
  );
}

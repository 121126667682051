import React, { useState, useEffect } from "react";
import { Trash2 } from "lucide-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../Loader/Loader";
import cartEmpty from "../../Assets/images/empty-cart.png";
import LocationButton from "./GetAddress";

// Skeleton loader component
const SkeletonLoader = ({ type }) => {
  switch (type) {
    case "cart-item":
      return (
        <div className="flex items-center space-x-4 border-b border-gray-300 pb-4 animate-pulse shadow-lg">
          <div className="w-32 h-32 bg-gray-200 rounded p-6 "></div>
          <div className="flex-1 space-y-4">
            <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
            <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
            <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
            <div className="flex items-center justify-between">
              <div className="h-6 w-16 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      );
    case "price-summary":
      return (
        <div className="shadow-lg border-gray-300 p-4">
          <div className="animate-pulse">
            <div className="h-6 w-3/4 bg-gray-200 rounded mb-4"></div>
            <div className="h-4 w-full bg-gray-200 rounded mb-3"></div>
            <div className="h-4 w-full bg-gray-200 rounded mb-3"></div>
            <div className="h-4 w-full bg-gray-200 rounded mb-3"></div>
          </div>
        </div>
      );
    case "form-field":
      return (
        <div className="h-10 w-full bg-gray-200 rounded mb-4 animate-pulse p-4"></div>
      );
    default:
      return null;
  }
};

const CheckoutPage = (props) => {
  const navigate = useNavigate();
  const { startLoader, stopLoader } = useLoader();
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [address, setAddress] = useState({
    fullName: "",
    phone: "",
    addressLine: "",
    city: "",
    state: "",
    postalCode: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCartData();
  }, []);

  const fetchCartData = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData && storedUserData.isLoggedIn) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/cart/user/${storedUserData._id}`
        );
        if (response.data.status) {
          setCartItems(response.data.data.cart.items);
          setTotalPrice(response.data.data.totalPrice);
          let count = response.data.data.cart.items.reduce(
            (sum, item) => sum + item.quantity,
            0
          );
          props.setCartCount(count);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching cart data:", error);
        setIsLoading(false);
      }
    }
  };

  const updateQuantity = async (productId, itemId, action) => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      const userId = storedUserData?._id;
      await axios.put(
        `${process.env.REACT_APP_API_URL}/cart/update/${itemId}`,
        {
          userId,
          productId,
          action,
        }
      );
      fetchCartData();
    } catch (error) {
      console.error(`Error in ${action}Quantity:`, error);
    }
  };

  const deleteCartItem = async (itemId) => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      const userId = storedUserData?._id;
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/cart/delete/${itemId}`,
        { data: { userId } }
      );
      fetchCartData();
    } catch (error) {
      console.error("Error deleting cart item:", error);
    }
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };
  // Function to handle payment initiation based on selected method
  const initiateOrder = async () => {
    console.log("Clicked" + paymentMethod);
    if (paymentMethod === "online") {
      initiatePhonePayPayment();
    } else if (paymentMethod === "cod") {
      initiateCOD();
    }
  };

  const initiateCOD = async () => {
    startLoader();
    setTimeout(() => {
      stopLoader();
      navigate("/successfulpayment");
    }, 3000);
  };

  const initiatePhonePayPayment = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));

    if (
      !address.fullName.trim() ||
      !address.phone.trim() ||
      !address.addressLine.trim() ||
      !address.city.trim() ||
      !address.state.trim() ||
      !address.postalCode.trim()
    ) {
      alert("Please fill in all address fields.");
      return;
    }

    if (!/^\d{10}$/.test(address.phone)) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }

    if (!/^\d{6}$/.test(address.postalCode)) {
      alert("Please enter a valid 6-digit postal code.");
      return;
    }

    try {
      const requestData = {
        transactionId: `txn${Date.now()}`,
        amount: totalPrice,
        userId: storedUserData._id,
        address: {
          fullName: address.fullName,
          phoneNumber: address.phone,
          address: address.addressLine,
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
        },
      };

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/payment/new-payment`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "X-MERCHANT-ID": process.env.MERCHANT_ID,
        },
        data: JSON.stringify(requestData),
      };

      const response = await axios.request(config);

      if (response.data.success) {
        const redirectUrl =
          response.data.data?.data?.instrumentResponse?.redirectInfo?.url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          alert("Redirect URL is missing or invalid.");
        }
      } else {
        alert("Order creation failed.");
      }
    } catch (error) {
      alert("Error initiating payment.");
    }
  };

  const handlePaymentCallback = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const transactionId = urlParams.get("transactionId");

    if (transactionId) {
      checkPaymentStatus(transactionId);
    } else {
      alert("Invalid callback URL.");
    }
  };

  const checkPaymentStatus = async (transactionId) => {
    try {
      const statusApiUrl = `${process.env.REACT_APP_API_URL}/payment/status?id=${transactionId}`;
      const response = await axios.get(statusApiUrl);

      if (response.data.success) {
        alert("Payment successful.");
      } else {
        alert("Payment failed.");
      }
    } catch (error) {
      alert("Error checking payment status.");
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="container text-center mt-20 mb-20">
          {/* Skeleton Loader on Both Boxes */}
          <div className="flex justify-between gap-10">
            {/* Left Box - Cart Items Skeleton */}
            <div className="w-full sm:w-1/2">
              <SkeletonLoader type="cart-item" />
            </div>

            {/* Right Box - Price Summary Skeleton */}
            <div className="w-full sm:w-1/3">
              <SkeletonLoader type="price-summary" />
            </div>
          </div>

          {/* Skeleton Loader for Address Form and Payment Method */}
          <div className="container mx-auto py-10 shadow-lg border-gray-300">
            <div className="bg-white rounded-lg ">
              <div className="">
                <SkeletonLoader type="form-field" />
                <SkeletonLoader type="form-field" />
                <SkeletonLoader type="form-field" />
                <SkeletonLoader type="form-field" />
                <SkeletonLoader type="form-field" />
                <SkeletonLoader type="form-field" />
              </div>

              <div className="mt-8 shadow-lg border-gray-300">
                <div className="flex items-center space-x-6">
                  <div>
                    <SkeletonLoader type="form-field" />
                  </div>
                  <div>
                    <SkeletonLoader type="form-field" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : cartItems.length === 0 ? (
        <div className="container text-center mt-20 mb-20">
          <img
            style={{ height: "auto", width: "100px" }}
            src={cartEmpty}
            className="mx-auto mb-4"
            alt="Cart Empty Icon"
          />
          <p className="text-xl font-semibold mb-2">
            You have no items to check out. Please add products to your cart
            before proceeding.
          </p>
          <button
            className="py-3 px-6 bg-black text-white rounded-md hover:bg-gray-800"
            onClick={() => (window.location.href = "/shop")}
          >
            Shop Now
          </button>
        </div>
      ) : (
        <div className="min-h-screen bg-gray-100">
          {/* Cart Items and Price Summary Above Address Form */}
          <div className="container mx-auto py-10 flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-10 justify-center">
            {/* Left Box - Cart Items */}
            <div className="flex-1 p-6 bg-white shadow-lg rounded-lg flex flex-col sm:w-1/2">
              <div className="flex-1">
                {cartItems.map((item) => (
                  <div
                    key={item._id}
                    className="flex items-center space-x-4 border-b border-gray-300 pb-4"
                  >
                    <div>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}/${item.productId.images[0]}`}
                        alt={item.productId.name}
                        className="object-cover rounded mt-3"
                        style={{ height: "135px", width: "135px" }}
                      />
                      <div className="flex items-center justify-center mt-2 gap-2">
                        <button
                          onClick={() =>
                            updateQuantity(
                              item.productId._id,
                              item._id,
                              "decrement"
                            )
                          }
                          className="px-4 py-2 bg-black text-white rounded-lg"
                        >
                          -
                        </button>
                        <div className="px-4 py-2 border border-gray-300 rounded-md">
                          <p className="text-lg">{item.quantity}</p>
                        </div>
                        <button
                          onClick={() =>
                            updateQuantity(
                              item.productId._id,
                              item._id,
                              "increment"
                            )
                          }
                          className="px-4 py-2 bg-black text-white rounded-md"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-medium">
                        {item.productId.name}
                      </h3>
                      <p className="text-gray-600">
                        Set your wardrobe with our Chrome Plus Jeans, combining
                        a sleek finish with premium stretch comfort.
                      </p>
                      <p className="text-gray-800 font-medium">
                        Price: ₹{item.productId.price}
                      </p>
                      <p className="text-gray-800 font-medium">
                        Size: {item.size}
                      </p>
                      <div className="flex items-center justify-between mt-2">
                        <p></p>
                        {/* <p className="text-sm">Size: {item.size}</p> */}
                        <button
                          className="p-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                          onClick={() => deleteCartItem(item._id)}
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Right Box - Price Summary */}
            <div className="w-full sm:w-1/3 p-6 shadow-lg rounded-lg bg-white">
              <h3 className="text-xl font-semibold mb-4">Price Summary</h3>
              <div className="flex justify-between font-semibold mt-3">
                <p className="font-medium">{`Price (${cartItems.length} item${
                  cartItems.length > 1 ? "s" : ""
                }):`}</p>
                <p className="font-medium">₹{totalPrice}</p>
              </div>
              <div className="flex justify-between font-semibold">
                <p className="font-medium">Delivery Charges:</p>
                <p className="font-medium">
                  <s>₹ 40</s> Free
                </p>
              </div>
              <div className="flex justify-between font-semibold">
                <p className="font-medium">Subtotal:</p>
                <p className="font-medium">₹{totalPrice}</p>
              </div>
            </div>
          </div>

          {/* Address and Payment Method Form */}
          <div className="container mx-auto bg-white p-6 rounded-lg shadow-lg mb-8">
            <div className="flex justify-between items-start">
              <h2 className="text-2xl font-semibold text-gray-700 mb-6 flex items-center">
                Shipping Address and Payment Method
              </h2>

              {/* Icon to fetch the geolocation */}
              <div>
                <LocationButton setAddress={setAddress} />
              </div>
            </div>

            <form onSubmit={(e) => e.preventDefault()}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    value={address.fullName}
                    onChange={handleAddressChange}
                    className="w-full p-3 border border-gray-300 rounded-md"
                    placeholder="Enter your full name"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={address.phone}
                    onChange={handleAddressChange}
                    className="w-full p-3 border border-gray-300 rounded-md"
                    placeholder="Enter your phone number"
                    pattern="^\d{10}$"
                    title="Phone number must be 10 digits"
                    required
                  />
                </div>
                <div className="sm:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Address
                  </label>
                  <input
                    type="text"
                    name="addressLine"
                    value={address.addressLine}
                    onChange={handleAddressChange}
                    className="w-full p-3 border border-gray-300 rounded-md"
                    placeholder="Enter your address"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={address.city}
                    onChange={handleAddressChange}
                    className="w-full p-3 border border-gray-300 rounded-md"
                    placeholder="Enter your city"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    State
                  </label>
                  <input
                    type="text"
                    name="state"
                    value={address.state}
                    onChange={handleAddressChange}
                    className="w-full p-3 border border-gray-300 rounded-md"
                    placeholder="Enter your state"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Postal Code
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    value={address.postalCode}
                    onChange={handleAddressChange}
                    className="w-full p-3 border border-gray-300 rounded-md"
                    placeholder="Enter your postal code"
                    pattern="^\d{6}$"
                    title="Postal code must be 6 digits"
                    required
                  />
                </div>
              </div>

              {/* Payment Method */}
              <div className="mt-8">
                <h2 className="text-2xl font-semibold text-gray-700 mb-4 flex items-center">
                  <i className="fas fa-credit-card mr-2"></i>
                  Payment Method
                </h2>
                <div className="flex items-center space-x-6">
                  <label className="flex items-center text-gray-700">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="online"
                      checked={paymentMethod === "online"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      className="mr-2"
                    />
                    Online Payment
                  </label>
                  {/* Uncomment for Cash on Delivery */}
                  {/* <label className="flex items-center text-gray-700">
              <input
                type="radio"
                name="paymentMethod"
                value="cod"
                checked={paymentMethod === "cod"}
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="mr-2"
              />
              Cash on Delivery
            </label> */}
                </div>
              </div>
            </form>
          </div>

          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              onClick={initiateOrder}
              className="w-full sm:w-2/5 py-3 bg-black text-white rounded-md hover:bg-gray-800 mb-5"
            >
              Place Order
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutPage;

import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import budysuit from "../../Assets/images/compressimg/AvantBudysuit.webp";
import RoseNoirTee from "../../Assets/images/compressimg/RoseNoirTee.webp";
import ChromePulseJeans from "../../Assets/images/compressimg/ChromePulseJeans.webp";
import TheWaveCoord from "../../Assets/images/compressimg/Wave.webp";
import FadedEchoTee from "../../Assets/images/compressimg/faded.webp";

// Define the bodysuitProductId
const bodysuitProductId = "6760760a6de32005e732d499";
const FadedEchoTeeProductId = "67794ca4089f04967e68cdd4";
const RoseNoirProductId = "67607b6803adcb329aa6d743";
const ChromePulseJeansProductId = "67611a0319371897305d7d49";
const WaveCoordProductId = "67611d6a19371897305d7d6d";

// Define products array with unique ids
const products = [
  {
    id: bodysuitProductId, // Bodysuit Product ID
    name: "Scarlet Seduction Bodysuit",
    price: 4999,
    image: budysuit,
    collection: "Genesis",
  },
  {
    id: RoseNoirProductId,
    name: "Rose Noir Tee",
    price: 4999,
    image: RoseNoirTee,
    collection: "Genesis",
  },
  {
    id: ChromePulseJeansProductId,
    name: "Chrome Pulse Jeans",
    price: 9500,
    image: ChromePulseJeans,
    collection: "Genesis",
  },
  {
    id: WaveCoordProductId,
    name: "The Wave Co-ord",
    price: 11000,
    image: TheWaveCoord,
    collection: "Genesis",
  },
  {
    id: FadedEchoTeeProductId,
    name: "Faded Echo Tee",
    price: 4499,
    image: FadedEchoTee,
    collection: "Genesis",
  },
  // Add more products with other collections as needed...
];

export default function CollectionPage() {
  const [showAll, setShowAll] = useState(false); // State to manage visibility of all products
  const [selectedCollection, setSelectedCollection] = useState("Genesis"); // State for selected collection

  const handleShowAll = () => {
    setShowAll(true); // Toggle to show all products when the button is clicked
  };

  const handleCollectionChange = (event) => {
    setSelectedCollection(event.target.value); // Change the selected collection based on dropdown value
  };

  // Filter products based on the selected collection
  const filteredProducts = products.filter(
    (product) => product.collection === selectedCollection
  );

  return (
    <div className="min-h-screen bg-[#f8f8f8]">
      {/* Products Grid */}
      <main className="container mx-auto px-6 py-4">
        <h1
          className="text-3xl font-bold "
          style={{ marginBottom: "10px", fontFamily: "math" }}
        >
          {selectedCollection}
        </h1>

        {/* Dropdown to select collection */}
        {/* <div className="mb-4">
          <select
            value={selectedCollection}
            onChange={handleCollectionChange}
            className="p-2 border rounded"
          >
            <option value="Genesis">Genesis</option>
            <option value="Collection 1">Collection 1</option>
            <option value="Collection 2">Collection 2</option>
            <option value="Collection 3">Collection 3</option>
           
          </select>
        </div> */}

        <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-2 md:gap-4 lg:gap-12">
          {filteredProducts
            .slice(
              0,
              showAll || window.innerWidth >= 1024 ? filteredProducts.length : 4
            )
            .map((product) => (
              <div key={product.id} className="group">
                {/* Link wrapping the product details */}
                <Link to={`/product-details/${product.id}`} className="block">
                  <div className="relative aspect-[4/5] mb-4 bg-gray-100 overflow-hidden">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="object-cover transition-transform duration-700 group-hover:scale-105"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-medium mb-1">
                        {product.name}
                      </h3>
                      {/* Displaying price in INR (₹) */}
                      <p className="text-gray-600">₹{product.price}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>

        {/* Show "Discover More" button only on mobile */}
        {!showAll && window.innerWidth < 1024 && (
          <div className="text-center mt-8 lg:hidden">
            <button
              onClick={handleShowAll}
              className="bg-black text-white px-4 py-2 text-sm uppercase tracking-wider"
            >
              Discover More
            </button>
          </div>
        )}
      </main>
    </div>
  );
}

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sizegide from "../../Assets/images/Avant Divine Size Chart-01.png";
import { useLoader } from "../Loader/Loader";
import "./ProductDetails.css";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";

export default function ProductDetails(props) {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedImage, setSelectedImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [activeTab, setActiveTab] = useState("PRODUCT INFORMATION");
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false);
  // const [isInWishlist, setIsInWishlist] = useState(null);
  const { startLoader, stopLoader } = useLoader();
  const navigate = useNavigate();
  const [isInWishlist, setIsInWishlist] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      const storedUserData = JSON.parse(localStorage.getItem("user"));

      // Construct the URL conditionally based on whether the user is logged in
      let url = `${process.env.REACT_APP_API_URL}/products/${productId}`;
      if (storedUserData && storedUserData.isLoggedIn) {
        url += `?userId=${storedUserData._id}`;
      }

      try {
        const response = await fetch(url);
        const data = await response.json();
        setProduct(data);
        setIsInWishlist(data.wishlist);
        console.log("Fetched product data:", JSON.stringify(data.wishlist));
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    if (productId) {
      fetchProduct();
    }
  }, [productId]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const totalScrollHeight = document.documentElement.scrollHeight;
      const scrollPercentage = (scrollPosition / totalScrollHeight) * 100;

      const imageIndex = Math.floor(
        (scrollPercentage / 100) * (product?.images?.length || 1)
      );
      setSelectedImage(imageIndex);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [product]);

  const handleWishlist = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (!storedUserData?._id) {
      window.location.href = "/login";
      return;
    }

    try {
      if (isInWishlist) {
        // Remove from wishlist
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/wishlist/remove`,
          {
            data: {
              userId: storedUserData._id,
              productId: productId,
            },
          }
        );

        if (response.status === 200) {
          console.log("Product removed from wishlist");
          setIsInWishlist(false);
        } else {
          console.error("Failed to remove from wishlist");
        }
      } else {
        // Add to wishlist (size check only here)
        if (!selectedSize) {
          alert("Please select a size");
          return;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/wishlist/add`,
          {
            userId: storedUserData._id,
            productId: productId,
            size: selectedSize,
            quantity: 1,
          }
        );

        if (response.status === 200) {
          console.log("Product added to wishlist");
          setIsInWishlist(true);
        } else {
          console.error("Failed to add to wishlist");
        }
      }
    } catch (error) {
      console.error("Error handling wishlist:", error);
    }
  };

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  const handleAddToCart = async () => {
    if (!selectedSize) {
      alert("Please select a size");
      return;
    }
    startLoader();
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));

      if (!storedUserData || !storedUserData._id) {
        window.location.href = "/login";
        stopLoader();
        return;
      }

      const userId = storedUserData._id;
      const cartItem = {
        userId,
        productId: product._id,
        quantity,
        size: selectedSize,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cartItem),
        }
      );

      if (response.ok) {
        console.log("Your item has been successfully added to the cart!");
        props.setCartCount(props.cartCount + quantity);
      } else {
        const errorData = await response.json();
        alert(`Error adding item to the cart: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      alert("An error occurred while adding the item to the cart.");
    } finally {
      stopLoader();
    }
  };

  const handleBuyNow = async () => {
    if (!selectedSize) {
      alert("Please select a size");
      return;
    }

    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (!storedUserData?._id) {
      window.location.href = "/login";
      return;
    }

    const cartItem = {
      userId: storedUserData._id,
      productId: product._id,
      quantity,
      size: selectedSize,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/add`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(cartItem),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
        return;
      }

      props.setCartCount(props.cartCount + quantity);

      navigate(`/buynow/${productId}`);
    } catch (error) {
      console.error("Error adding item to cart:", error);
      alert("An error occurred.");
    }
  };

  const handleToggleSizeGuide = () => {
    setIsSizeGuideOpen(!isSizeGuideOpen); // Toggle popup visibility
  };

  // const handleToggleWishlist = () => {
  //   setIsInWishlist(!isInWishlist);
  //   if (!isInWishlist) {
  //     toast.success("Added to Wishlist!");
  //   } else {
  //     toast.info("Removed from Wishlist!");
  //   }
  // };

  if (!product) return <div>Loading...</div>;

  const sizes = product.sizes || [];
  const tabs = ["PRODUCT INFORMATION", "SHIPPING & RETURNS"];

  return (
    <div className="max-w-7xl mx-auto px-4 py-0 sm:py-8 grid grid-cols-1 lg:grid-cols-2 gap-0 sm:gap-8">
      <div className="relative">
        <div
          className="hidden lg:flex flex-col gap-4"
          style={{
            height: "800px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {product.images &&
            product.images.map((img, index) => (
              <button
                key={index}
                onClick={() => setSelectedImage(index)}
                className="w-full animate-border"
              >
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}/${img}`}
                  alt={`Product Image ${index + 1}`}
                  className="w-full object-cover"
                />
              </button>
            ))}
        </div>

        {/* Horizontal Image Gallery for Mobile (Show 2 images at a time) */}
        <div
          className="lg:hidden flex gap-4 overflow-x-auto py-4"
          style={{
            maxWidth: "100%",
            scrollSnapType: "x mandatory",
            scrollBehavior: "smooth",
            scrollPadding: "10px", // Optional to avoid clipping images at edges
          }}
        >
          {product.images &&
            product.images.map((img, index) => (
              <button
                key={index}
                onClick={() => setSelectedImage(index)}
                className={`flex-shrink-0 border ${
                  selectedImage === index ? "border-black" : "border-gray-200"
                }`}
                style={{ scrollSnapAlign: "center" }}
              >
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}/${img}`}
                  alt={`Product Image ${index + 1}`}
                  className="w-[90vw] h-[auto] object-cover"
                />
              </button>
            ))}
        </div>
      </div>

      {/* Product Details */}
      <div className="space-y-2 sm:space-y-6">
        <div className="flex justify-between items-center space-x-4 w-full">
          <h1 className="text-2xl font-medium">{product.name}</h1>
          <button
            onClick={handleWishlist}
            className={`flex items-center gap-2 mt-4 text-lg transition-all ${
              isInWishlist ? "text-black" : "text-gray-600"
            }`}
          >
            {isInWishlist ? (
              <AiFillHeart size={24} />
            ) : (
              <AiOutlineHeart size={24} />
            )}
          </button>
        </div>

        <p className="mt-2 text-gray-600" style={{ textAlign: "justify" }}>
          {product.description}
        </p>

        {/* Size Selection */}
        <div>
          <h2 className="text-sm font-medium ">Select Size</h2>
          <div className="flex gap-2">
            {sizes.map((size) => (
              <button
                key={size}
                onClick={() => setSelectedSize(size)}
                className={`w-12 h-12 border ${
                  selectedSize === size
                    ? "border-black bg-black text-white"
                    : "border-gray-200 hover:border-black"
                }`}
              >
                {size}
              </button>
            ))}
          </div>
        </div>

        {/* Size Guide Button */}
        <div>
          <button
            onClick={handleToggleSizeGuide}
            className="text-sm text-blue-600 hover:underline mt-0 sm:mt-4"
          >
            Size Guide
          </button>
        </div>

        {/* Quantity Selection */}
        <div>
          <h2 className="text-sm font-medium mb-2">Quantity</h2>
          <div className="flex items-center gap-4">
            <button
              onClick={handleDecreaseQuantity}
              className="w-10 h-10 bg-gray-200 text-black text-lg flex justify-center items-center border border-gray-300 hover:bg-gray-300"
            >
              -
            </button>
            <p className="text-lg">{quantity}</p>
            <button
              onClick={handleIncreaseQuantity}
              className="w-10 h-10 bg-gray-200 text-black text-lg flex justify-center items-center border border-gray-300 hover:bg-gray-300"
            >
              +
            </button>
          </div>
        </div>

        {/* Price and Action Buttons */}
        <div className="space-y-4">
          <p className="text-xl">
            ₹{(product.price * quantity).toLocaleString()}
          </p>

          {productId === "67611d6a19371897305d7d6d" ? (
            <button className="w-full bg-[#14161A] text-white py-3 px-4 hover:bg-black transition-colors">
              SOLD OUT
            </button>
          ) : (
            <>
              <button
                onClick={handleAddToCart}
                className="w-full bg-[#14161A] text-white py-3 px-4 hover:bg-black transition-colors"
              >
                ADD TO CART
              </button>
              <button
                onClick={handleBuyNow}
                className="w-full bg-red-600 text-white py-3 px-4 hover:bg-red-700 transition-colors mt-2"
              >
                BUY NOW
              </button>
            </>
          )}
        </div>

        {/* Product Information Tabs */}
        <div className="pt-2 sm:pt-8">
          <div className="flex border-b">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-4 py-2 text-sm ${
                  activeTab === tab
                    ? "border-b-2 border-black"
                    : "text-gray-500"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="pt-4">
            {activeTab === "PRODUCT INFORMATION" ? (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <p className="font-medium">Material</p>
                    <p className="text-gray-600">
                      {product.productInfo.material}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium">Weight</p>
                    <p className="text-gray-600">
                      {product.productInfo.weight}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium">Country of origin</p>
                    <p className="text-gray-600">
                      {product.productInfo.countryOfOrigin}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium">Dimensions</p>
                    <p className="text-gray-600">
                      {product.productInfo.dimensions}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium">Type</p>
                    <p className="text-gray-600">{product.productInfo.type}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-sm text-gray-600">
                <p>{product.shippingAndReturns}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Size Guide Popup */}
      {isSizeGuideOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleToggleSizeGuide}
        >
          <div
            className="bg-white p-6 max-w-full relative"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <button
              onClick={handleToggleSizeGuide}
              className="absolute top-2 right-2 text-xl text-black"
            >
              X
            </button>
            <img src={Sizegide} alt="Size Guide" style={{ width: "600px" }} />
          </div>
        </div>
      )}
    </div>
  );
}

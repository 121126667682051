import React, { createContext, useContext, useState, useEffect } from "react";
import Iconloader from "../../Assets/images/compressimg/favicon.png"; // Import the custom icon
import "./Loader.css";

const LoaderContext = createContext();

export const LoaderProviderComponent = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(true); // State to control website refresh

  useEffect(() => {
    // Simulate a refresh effect on first load
    setTimeout(() => {
      setRefreshing(false);
    }, 1000); // Adjust this timeout to control initial load time
  }, []);

  const startLoader = () => setLoading(true);
  const stopLoader = () => setLoading(false);

  return (
    <LoaderContext.Provider value={{ startLoader, stopLoader }}>
      {(refreshing || loading) && (
        <section className="loader-container">
          {/* Use the custom icon as the loader */}
          <img
            style={{ height: "250px", width: "400px" }}
            src={Iconloader}
            alt="Loading..."
            className="custom-loader-icon" // Add a custom class for styling
          />
        </section>
      )}
      {!refreshing && !loading && children}
    </LoaderContext.Provider>
  );
};

// Custom hook to use the loader
export const useLoader = () => {
  return useContext(LoaderContext);
};

import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom"; // Ensure Link is imported
import Img from "../../Assets/images/compressimg/RoseNoirTee.webp";

const SignupForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(""); // Clear any previous error message

    try {
      console.log("Submitting form data:", formData);

      // Send POST request to backend API for signup
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/signup`,
        formData
      );

      console.log("API Response:", response);
      console.log("Signup successful, redirecting to login...");

      // navigate("/login" );
      navigate("/signup-otp-verify", {
        state: { email: formData.email, password: formData.password },
      });
    } catch (error) {
      console.error("API error:", error);
      setErrorMessage(
        error.response?.data?.message || "Signup failed. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-w-screen flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: "1000px" }}
      >
        <div className="md:flex w-full">
          <div className="hidden md:block w-1/2 bg-gradient-to-r from-gray-800 via-gray-900 to-black ">
            <img src={Img} alt="Signup" />
          </div>
          <div
            className="w-full md:w-1/2 py-10 px-5 md:px-10 bg-white"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-center ">
              <h1 className="font-bold text-3xl text-gray-900">REGISTER</h1>
              <p>Enter your information to register</p>
            </div>
            <form onSubmit={handleSubmit}>
              {/* Name Field */}
              <div className="flex -mx-3">
                <div className="px-3 mb-5" style={{ width: "100%" }}>
                  <label htmlFor="name" className="text-xs font-semibold px-1">
                    First Name
                  </label>
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <i className="mdi mdi-account-outline text-gray-200 text-lg"></i>
                    </div>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="w-full -ml-10 pl-4 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black"
                      placeholder="John"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {/* Email Field */}
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-5">
                  <label htmlFor="email" className="text-xs font-semibold px-1">
                    Email
                  </label>
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <i className="mdi mdi-email-outline text-gray-400 text-lg"></i>
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="w-full -ml-10 pl-4 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black"
                      placeholder="johnsmith@example.com"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {/* Phone Number Field */}
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-5">
                  <label
                    htmlFor="phoneNumber"
                    className="text-xs font-semibold px-1"
                  >
                    Phone Number
                  </label>
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <i className="mdi mdi-phone-outline text-gray-400 text-lg"></i>
                    </div>
                    <input
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="w-full -ml-10 pl-4 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black"
                      placeholder="1234567890"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {/* Password Field */}
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-12">
                  <label
                    htmlFor="password"
                    className="text-xs font-semibold px-1"
                  >
                    Password
                  </label>
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <i className="mdi mdi-lock-outline text-gray-400 text-lg"></i>
                    </div>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="w-full -ml-10 pl-4 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black"
                      placeholder="************"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {/* Error Message */}
              {errorMessage && (
                <p className="text-red-500 text-xs">{errorMessage}</p>
              )}

              {/* Submit Button */}
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-5">
                  <button
                    type="submit"
                    className="block w-full max-w-xs mx-auto  from-gray-800 via-gray-900 to-black hover:bg-black text-white rounded-lg px-3 py-3 font-semibold"
                    style={{ backgroundColor: "#191919" }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <span className="dots-container">
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                      </span>
                    ) : (
                      "REGISTER NOW"
                    )}
                  </button>
                </div>
              </div>
            </form>
            <div className="text-center ">
              <p className=" text-gray-600" style={{ fontSize: "larger" }}>
                Already have an account?{" "}
                <Link to="/login" className="text-blue-500">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;

"use client";

import { useState } from "react";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import OtpVerify from "./OtpVerify";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";

export default function AuthForm() {
  const [currentView, setCurrentView] = useState("login");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleSignupComplete = (phone) => {
    setPhoneNumber(phone);
    setCurrentView("otp");
  };

  const handleOtpVerified = () => {
    setCurrentView("login");
  };

  const handleForgotPassword = (userEmail) => {
    setEmail(userEmail);
    setCurrentView("otp");
  };

  const handlePasswordResetOtpVerified = () => {
    setCurrentView("reset-password");
  };

  const handlePasswordReset = () => {
    setCurrentView("login");
  };

  const renderForm = () => {
    switch (currentView) {
      case "login":
        return (
          <LoginForm
            onSignupClick={() => setCurrentView("signup")}
            onForgotPasswordClick={() => setCurrentView("forgot-password")}
          />
        );
      case "signup":
        return (
          <SignupForm
            onLoginClick={() => setCurrentView("login")}
            onSignupComplete={handleSignupComplete}
          />
        );
      case "otp":
        return (
          <OtpVerify
            phoneNumber={phoneNumber}
            email={email}
            onVerificationComplete={
              email ? handlePasswordResetOtpVerified : handleOtpVerified
            }
            onBackClick={() =>
              setCurrentView(email ? "forgot-password" : "signup")
            }
            type={email ? "reset" : "signup"}
          />
        );
      case "forgot-password":
        return (
          <ForgotPasswordForm
            onSubmit={handleForgotPassword}
            onBackToLogin={() => setCurrentView("login")}
          />
        );
      case "reset-password":
        return (
          <ResetPasswordForm
            email={email}
            onPasswordReset={handlePasswordReset}
          />
        );
      default:
        return null;
    }
  };

  const renderRightPanelText = () => {
    switch (currentView) {
      case "login":
        return {
          title: "Welcome Back!",
          description:
            "To keep connected with us please login with your personal info",
        };
      case "signup":
        return {
          title: "Hello, Friend!",
          description: "Enter your personal details and start journey with us",
        };
      case "otp":
        return {
          title: "Almost There!",
          description: "Please verify your code to continue",
        };
      case "forgot-password":
        return {
          title: "Reset Password",
          description: "Enter your email to reset your password",
        };
      case "reset-password":
        return {
          title: "Create New Password",
          description: "Choose a strong password for your account",
        };
      default:
        return {};
    }
  };

  const { title, description } = renderRightPanelText();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full flex rounded-xl shadow-2xl overflow-hidden">
        {/* Left Panel - Forms */}
        <div className="w-full md:w-1/2 bg-white p-8">
          <div className="transition-all duration-500 transform">
            {renderForm()}
          </div>
        </div>

        {/* Right Panel - Welcome Message */}
        <div className="hidden md:block w-1/2 bg-gradient-to-br from-rose-500 to-rose-600 p-12 text-white">
          <div className="h-full flex items-center justify-center">
            <div className="text-center">
              <h2 className="text-4xl font-bold mb-4">{title}</h2>
              <p className="mb-8">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

"use client";

import { useState } from "react";
import { X } from "lucide-react";
import Img from "../../Assets/images/Resized Images/Poup.jpg";

export default function Popup() {
  const [isOpen, setIsOpen] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    marketing: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white w-full max-w-3xl rounded-lg overflow-hidden relative">
        <button
          onClick={handleClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 z-10"
        >
          <X className="h-6 w-6" />
          <span className="sr-only">Close</span>
        </button>

        <div className="grid md:grid-cols-2 gap-0">
          {/* Left side - Image */}
          <div className="hidden md:block">
            <img
              src={Img}
              alt="Fashion collection preview"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Right side - Form */}
          <div className="p-6">
            <div className="space-y-4 max-w-sm mx-auto">
              <div className="text-center space-y-2">
                <h2 className="text-2xl font-bold text-gray-900">
                  Build a Divine Community with Us!
                </h2>
                <p className="text-sm text-gray-600">
                  Sign in now and be the first in line to grab our brand-new
                  collections! Get ahead of the crowd and indulge in divine
                  fashion before anyone else. Don't miss out!
                </p>
              </div>

              <form onSubmit={handleSubmit} className="space-y-3">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    required
                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    required
                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>

                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    required
                    className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                    placeholder="Enter your phone number"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                </div>

                {/* <div className="flex items-start space-x-2">
                  <input
                    type="checkbox"
                    id="marketing"
                    className="mt-1"
                    checked={formData.marketing}
                    onChange={(e) =>
                      setFormData({ ...formData, marketing: e.target.checked })
                    }
                  />
                  <label htmlFor="marketing" className="text-xs text-gray-600">
                    Yes, I want to receive exclusive fashion updates, early
                    access to sales, and VIP invites. View our{" "}
                    <a
                      href="#"
                      className="text-purple-600 underline hover:text-purple-700"
                    >
                      Privacy Policy
                    </a>
                    .
                  </label>
                </div> */}

                <button
                  type="submit"
                  className="w-full bg-black text-white py-2 rounded-lg font-semibold text-sm  transition-colors"
                >
                  JOIN NOW
                </button>

                <div className="text-center">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="text-gray-600 text-sm hover:underline"
                  >
                    Maybe Later
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

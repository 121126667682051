import React, { useState } from "react";

const mockWishlist = [
  {
    id: 1,
    name: "Slim Fit Jeans",
    price: 59.99,
    image: "/placeholder.svg?height=100&width=100",
  },
  {
    id: 2,
    name: "Cotton T-Shirt",
    price: 24.99,
    image: "/placeholder.svg?height=100&width=100",
  },
  {
    id: 3,
    name: "Leather Jacket",
    price: 199.99,
    image: "/placeholder.svg?height=100&width=100",
  },
];

export default function Wishlist() {
  const [wishlist, setWishlist] = useState(mockWishlist);

  const removeFromWishlist = (id) => {
    setWishlist(wishlist.filter((item) => item.id !== id));
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Wishlist</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {wishlist.map((item) => (
          <div
            key={item.id}
            className="border rounded-lg p-4 flex flex-col items-center"
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-32 h-32 object-cover mb-2"
            />
            <h3 className="font-semibold">{item.name}</h3>
            <p className="text-gray-600">${item.price.toFixed(2)}</p>
            <button
              onClick={() => removeFromWishlist(item.id)}
              className="mt-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

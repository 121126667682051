import React, { useState } from "react";

export default function AddressDetails() {
  const [addresses, setAddresses] = useState([
    {
      id: 1,
      type: "Home",
      street: "123 Main St",
      city: "Anytown",
      state: "CA",
      zip: "12345",
    },
  ]);

  const [newAddress, setNewAddress] = useState({
    type: "",
    street: "",
    city: "",
    state: "",
    zip: "",
  });

  const addAddress = (e) => {
    e.preventDefault();
    setAddresses([...addresses, { id: Date.now(), ...newAddress }]);
    setNewAddress({ type: "", street: "", city: "", state: "", zip: "" });
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Address Details</h2>
      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Saved Addresses</h3>
        {addresses.map((address) => (
          <div key={address.id} className="bg-gray-100 p-4 rounded-lg mb-2">
            <p className="font-semibold">{address.type}</p>
            <p>{address.street}</p>
            <p>
              {address.city}, {address.state} {address.zip}
            </p>
          </div>
        ))}
      </div>
      <form onSubmit={addAddress} className="space-y-4">
        <div>
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700"
          >
            Address Type
          </label>
          <input
            type="text"
            id="type"
            value={newAddress.type}
            onChange={(e) =>
              setNewAddress({ ...newAddress, type: e.target.value })
            }
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            required
          />
        </div>
        <div>
          <label
            htmlFor="street"
            className="block text-sm font-medium text-gray-700"
          >
            Street
          </label>
          <input
            type="text"
            id="street"
            value={newAddress.street}
            onChange={(e) =>
              setNewAddress({ ...newAddress, street: e.target.value })
            }
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            required
          />
        </div>
        <div className="flex space-x-4">
          <div className="flex-1">
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              City
            </label>
            <input
              type="text"
              id="city"
              value={newAddress.city}
              onChange={(e) =>
                setNewAddress({ ...newAddress, city: e.target.value })
              }
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div className="flex-1">
            <label
              htmlFor="state"
              className="block text-sm font-medium text-gray-700"
            >
              State
            </label>
            <input
              type="text"
              id="state"
              value={newAddress.state}
              onChange={(e) =>
                setNewAddress({ ...newAddress, state: e.target.value })
              }
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div className="flex-1">
            <label
              htmlFor="zip"
              className="block text-sm font-medium text-gray-700"
            >
              ZIP Code
            </label>
            <input
              type="text"
              id="zip"
              value={newAddress.zip}
              onChange={(e) =>
                setNewAddress({ ...newAddress, zip: e.target.value })
              }
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600 transition-colors"
        >
          Add New Address
        </button>
      </form>
    </div>
  );
}

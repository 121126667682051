import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Navbar from "./Components/Header/Navbar";
import { CartProvider } from "./Components/CartContext/CartContext";
import CollectionsSection from "./Components/Collections/Collections";
import Bodysuit from "./Components/ProducCollactions/Bodysuit";
import ProductDetails from "./Components/ProductDetails/ProductDetails";
import VideoBanner from "./Components/VideoBanner/VideoBanner";
import CheckoutPage from "./Components/Checkout/Checkout";
import BuyNowPage from "./Components/Checkout/BuyNow";
import Footer from "./Components/Footer/footer"; // Your Footer
import SuccessfulPayment from "./Components/SuccessfulPayment/SuccessfulPayment";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./Components/TermsAndConditions/PrivacyPolicy";
import ReturnsandExchange from "./Components/TermsAndConditions/ReturnsandExchange";
import ShippingAndDelivery from "./Components/TermsAndConditions/ShippingDelivery";
import SignUp from "./Components/LoginSignup/Signup";
// import Login from "./Components/LoginSignup/Login";
import CallUs from "./Components/CallUs/CallUs";
import Cart from "./Components/Header/Cart";
import AboutPage from "./Components/AboutUs/AboutUs";
import { LoaderProviderComponent } from "./Components/Loader/Loader";
import ScrollToTop from "./Components/ScrollOnTop/ScrollOnTop";
import { useEffect, useState } from "react";
import axios from "axios";
import AuthLogin from "./Components/Auth/Auth";
import Profile from "./Components/Profile/Profile";
import UserProfile from "./Components/ProfileNew/UserProfile";
import OrderDetails from "./Components/ProfileNew/OrderPage";
import AccountSettingsPage from "./Components/ProfileNew/AccountSettingsPage";
import AvantCreditsPage from "./Components/ProfileNew/AvantCreditsPage";
import PaymentMethodsPage from "./Components/ProfileNew/PaymentMethodsPage";
import WishlistPage from "./Components/ProfileNew/WhistList";
import Poup from "../src/Components/Poup/Poup";
// import LoginForm from "./Components/Auth/LoginForm";
// import User from "./Components/User/Auth";
// import Signup from "./Components/User/SignupForm";
import LoginForm from "./Components/User/LoginForm";
import SignupForm from "./Components/User/SignupForm";
import ForgotPassword from "./Components/User/ForgotPassword";
import SignupOTPVerify from "./Components/User/SignupOTPVerify";
import RecoverOTP from "./Components/User/RecoverOTP";
import SetNewPassword from "./Components/User/ResetPassword";

function App() {
  const [cartCount, setCartCount] = useState(0);
  const [showPoup, setShowPoup] = useState(false); // State to track the Poup visibility

  const fetchCartData = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData && storedUserData.isLoggedIn) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/cart/user/${storedUserData._id}`
        );
        if (response.data.status) {
          let count = response.data.data.cart.items.reduce(
            (sum, item) => sum + item.quantity,
            0
          );
          setCartCount(count);
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    }
  };

  useEffect(() => {
    fetchCartData();

    const timer = setTimeout(() => {
      setShowPoup(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <LoaderProviderComponent>
      <CartProvider>
        <Router>
          <ScrollToTop />
          <div className="main-container">
            {" "}
            {/* Root container with flex layout */}
            <Navbar cartCount={cartCount} setCartCount={setCartCount} />
            <div className="content">
              {" "}
              {/* Content takes available space */}
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/collections" element={<CollectionsSection />} />
                <Route path="/bodysuit" element={<Bodysuit />} />
                <Route path="/videobanner" element={<VideoBanner />} />
                <Route
                  path="/checkoutpage"
                  element={
                    <CheckoutPage
                      cartCount={cartCount}
                      setCartCount={setCartCount}
                    />
                  }
                />
                <Route
                  path="/buynow/:productId"
                  element={
                    <BuyNowPage
                      cartCount={cartCount}
                      setCartCount={setCartCount}
                    />
                  }
                />
                <Route path="/shop" element={<CollectionsSection />} />
                <Route
                  path="/product-details/:productId"
                  element={
                    <ProductDetails
                      cartCount={cartCount}
                      setCartCount={setCartCount}
                    />
                  }
                />
                <Route path="/aboutus" element={<AboutPage />} />
                <Route
                  path="/successfulpayment"
                  element={<SuccessfulPayment />}
                />
                <Route
                  path="/termsAndConditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                <Route
                  path="/returnsandexchange"
                  element={<ReturnsandExchange />}
                />
                <Route
                  path="/shippinganddelivery"
                  element={<ShippingAndDelivery />}
                />
                {/* <Route path="/signup" element={<SignUp />} /> */}
                {/* <Route path="/login" element={<Login />} /> */}
                <Route path="/callus" element={<CallUs />} />
                <Route path="/authlogin" element={<AuthLogin />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/poup" element={<Poup />} />
                <Route path="/loginform" element={<LoginForm />} />
                {/* User Profile Layout with Nested Routes */}
                <Route path="/userprofile" element={<UserProfile />}></Route>
                <Route path="/order" element={<OrderDetails />} />
                <Route
                  path="/accountsetting"
                  element={<AccountSettingsPage />}
                />
                <Route path="/avantcredit" element={<AvantCreditsPage />} />
                <Route path="/paymentmethod" element={<PaymentMethodsPage />} />
                <Route path="/whistlist" element={<WishlistPage />} />
                <Route
                  path="/cart"
                  element={
                    <Cart cartCount={cartCount} setCartCount={setCartCount} />
                  }
                />
                {/* ------------USER START--------------- */}
                <Route path="/login" element={<LoginForm />} />
                <Route path="/register" element={<SignupForm />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/signup-otp-verify"
                  element={<SignupOTPVerify />}
                />
                <Route path="/recover-otp" element={<RecoverOTP />} />
                <Route path="/set-new-password" element={<SetNewPassword />} />
                {/* ------------USER END--------------- */}
              </Routes>
              {/* {showPoup && <Poup />} */}
            </div>
            <Footer />
          </div>
        </Router>
      </CartProvider>
    </LoaderProviderComponent>
  );
}

export default App;

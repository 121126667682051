import React, { useState } from "react";

export default function PaymentSettings() {
  const [cards, setCards] = useState([
    { id: 1, last4: "4242", brand: "Visa", expMonth: 12, expYear: 2024 },
  ]);

  const [newCard, setNewCard] = useState({
    number: "",
    expMonth: "",
    expYear: "",
    cvc: "",
  });

  const addCard = (e) => {
    e.preventDefault();
    // In a real app, you would send this info to your payment processor
    setCards([
      ...cards,
      {
        id: Date.now(),
        last4: newCard.number.slice(-4),
        brand: "New Card",
        expMonth: parseInt(newCard.expMonth),
        expYear: parseInt(newCard.expYear),
      },
    ]);
    setNewCard({ number: "", expMonth: "", expYear: "", cvc: "" });
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Payment Settings</h2>
      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Saved Cards</h3>
        {cards.map((card) => (
          <div key={card.id} className="bg-gray-100 p-4 rounded-lg mb-2">
            <p>
              {card.brand} ending in {card.last4}
            </p>
            <p>
              Expires {card.expMonth}/{card.expYear}
            </p>
          </div>
        ))}
      </div>
      <form onSubmit={addCard} className="space-y-4">
        <div>
          <label
            htmlFor="cardNumber"
            className="block text-sm font-medium text-gray-700"
          >
            Card Number
          </label>
          <input
            type="text"
            id="cardNumber"
            value={newCard.number}
            onChange={(e) => setNewCard({ ...newCard, number: e.target.value })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            required
          />
        </div>
        <div className="flex space-x-4">
          <div className="flex-1">
            <label
              htmlFor="expMonth"
              className="block text-sm font-medium text-gray-700"
            >
              Expiration Month
            </label>
            <input
              type="text"
              id="expMonth"
              value={newCard.expMonth}
              onChange={(e) =>
                setNewCard({ ...newCard, expMonth: e.target.value })
              }
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div className="flex-1">
            <label
              htmlFor="expYear"
              className="block text-sm font-medium text-gray-700"
            >
              Expiration Year
            </label>
            <input
              type="text"
              id="expYear"
              value={newCard.expYear}
              onChange={(e) =>
                setNewCard({ ...newCard, expYear: e.target.value })
              }
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div className="flex-1">
            <label
              htmlFor="cvc"
              className="block text-sm font-medium text-gray-700"
            >
              CVC
            </label>
            <input
              type="text"
              id="cvc"
              value={newCard.cvc}
              onChange={(e) => setNewCard({ ...newCard, cvc: e.target.value })}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600 transition-colors"
        >
          Add New Card
        </button>
      </form>
    </div>
  );
}

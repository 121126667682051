import React, { useState } from "react";
import axios from "axios";
import OrderPage from "./OrderPage";
import Wishlist from "./WhistList";
import AvantCreditsPage from "./AvantCreditsPage";
import AccountSettingsPage from "./AccountSettingsPage";
import PaymentMethodsPage from "./PaymentMethodsPage";
import { Link } from "react-router-dom";

export default function RootLayout({ children }) {
  const [activeView, setActiveView] = useState("orders");

  const handleNavigation = (view) => {
    setActiveView(view);
  };

  // Handle logout functionality
  const handleLogout = async () => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser && storedUser.email) {    
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {
          email: storedUser.email,
        });
        localStorage.clear();
        setActiveView("orders");
        window.location.href = "/";
      } catch (error) {
        console.error("Error logging out:", error);
      }
    }
  };

  return (
    <div className="flex min-h-screen bg-[#faf9f8]">
      {/* Sidebar visible on mobile */}
      <aside className="w-full md:w-64 mt-16 border-r border-gray-200 p-8 fixed top-0 left-0 bottom-0 bg-white z-10 md:hidden">
        <ul className="space-y-4">
          <li>
            <Link to="/order" className="block text-lg hover:text-blue-500">
              Your Orders
            </Link>
          </li>
          <li>
            <Link to="/whistlist" className="block text-lg hover:text-blue-500">
              Wishlist
            </Link>
          </li>
          <li>
            <Link
              to="/avantcredit"
              className="block text-lg hover:text-blue-500"
            >
              Avant Credits
            </Link>
          </li>
          <li>
            <Link
              to="/accountsetting"
              className="block text-lg hover:text-blue-500"
            >
              Account Settings
            </Link>
          </li>
          <li>
            <Link
              to="/paymentmethod"
              className="block text-lg hover:text-blue-500"
            >
              Payment Methods
            </Link>
          </li>
          <li>
            <button
              onClick={handleLogout} // Log the user out when this button is clicked
              className="block text-lg hover:text-blue-500"
            >
              Sign Out
            </button>
          </li>
        </ul>
      </aside>

      {/* Sidebar visible on desktop only */}
      <aside className="w-64 border-r border-gray-200 p-8 hidden md:block">
        {/* <div className="mb-12">
          <img
            src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            alt="Badge"
            width={100}
            height={100}
            className="mx-auto rounded-full"
          />
        </div> */}
        <nav className="space-y-4">
          <button
            onClick={() => handleNavigation("orders")}
            className="block text-gray-600 hover:text-black"
          >
            Your Orders
          </button>
          <button
            onClick={() => handleNavigation("wishlist")}
            className="block text-gray-600 hover:text-black"
          >
            Wishlist
          </button>
          <button
            onClick={() => handleNavigation("avantcredit")}
            className="block text-gray-600 hover:text-black"
          >
            Avant Credits
          </button>
          <button
            onClick={() => handleNavigation("accountsetting")}
            className="block text-gray-600 hover:text-black"
          >
            Account Settings
          </button>
          <button
            onClick={() => handleNavigation("paymentmethod")}
            className="block text-gray-600 hover:text-black"
          >
            Payment Methods
          </button>
          <button
            onClick={handleLogout} // Log the user out when this button is clicked
            className="block text-gray-600 hover:text-black"
          >
            Sign Out
          </button>
        </nav>
      </aside>

      {/* Main Content (Desktop and Mobile View) */}
      <main className="flex-1 md:p-12 pt-20 md:pt-12">
        {/* Conditionally render content based on activeView */}
        {activeView === "orders" && <OrderPage />}
        {activeView === "wishlist" && <Wishlist />}
        {activeView === "avantcredit" && <AvantCreditsPage />}
        {activeView === "accountsetting" && <AccountSettingsPage />}
        {activeView === "paymentmethod" && <PaymentMethodsPage />}
        {activeView === "signout" && <div>Sign Out Content</div>}
      </main>
    </div>
  );
}

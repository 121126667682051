import React from "react";

const ResetPasswordForm = ({ email, onPasswordReset }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle password reset logic here
    onPasswordReset();
  };

  return (
    <div className="w-full">
      <h2 className="text-3xl font-bold text-center mb-8">
        Create New Password
      </h2>

      <p className="text-center text-gray-600 mb-8">
        Create a new password for
        <br />
        <span className="font-medium">{email}</span>
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <input
            type="password"
            name="password"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="New Password"
            minLength={8}
          />
        </div>
        <div>
          <input
            type="password"
            name="confirmPassword"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="Confirm New Password"
            minLength={8}
          />
        </div>

        <button
          type="submit"
          className="w-full bg-rose-500 text-white py-2 rounded-md hover:bg-rose-600 transition-colors"
        >
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordForm;

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ onSignupClick, onForgotPasswordClick }) => {
  // State management
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  // Social login handler (can be customized)
  const handleSocialLogin = (provider) => {
    console.log(`Logging in with ${provider}`);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(""); // Reset error message on new submission

    try {
      // Send login credentials to backend API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/signin`,
        {
          email: formData.email,
          password: formData.password,
        }
      );

      if (response.status === 200) {
        const { token, user } = response.data;

        // Store token and filtered user in local storage
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));

        navigate("/"); // Redirect to the home or dashboard page
        window.location.reload();
      }
    } catch (error) {
      // Handle error
      setErrorMessage(
        error.response?.data?.message || "Login failed. Please try again."
      );
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="w-full">
      <h2 className="text-3xl font-bold text-center mb-8">Sign in</h2>

      {/* Social login buttons (Optional) */}
      {/* <div className="flex justify-center space-x-4 mb-8">
        <button
          onClick={() => handleSocialLogin('facebook')}
          className="p-2 rounded-full border hover:bg-gray-50 transition-colors"
        >
          <span className="sr-only">Sign in with Facebook</span>
          f
        </button>
        <button
          onClick={() => handleSocialLogin('google')}
          className="p-2 rounded-full border hover:bg-gray-50 transition-colors"
        >
          <span className="sr-only">Sign in with Google</span>
          G+
        </button>
        <button
          onClick={() => handleSocialLogin('linkedin')}
          className="p-2 rounded-full border hover:bg-gray-50 transition-colors"
        >
          <span className="sr-only">Sign in with LinkedIn</span>
          in
        </button>
      </div> */}

      {/* Login form */}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <input
            type="email"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="Email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </div>
        <div>
          <input
            type="password"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="Password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              type="checkbox"
              className="h-4 w-4 text-rose-500 focus:ring-rose-500 border-gray-300 rounded"
              checked={formData.rememberMe}
              onChange={(e) =>
                setFormData({ ...formData, rememberMe: e.target.checked })
              }
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900"
            >
              Remember me
            </label>
          </div>
          <button
            type="button"
            onClick={onForgotPasswordClick}
            className="text-sm text-rose-500 hover:text-rose-600"
          >
            Forgot password?
          </button>
        </div>

        {/* Error Message */}
        {errorMessage && (
          <div className="text-red-600 text-sm text-center">{errorMessage}</div>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-rose-500 text-white py-2 rounded-md hover:bg-rose-600 transition-colors"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Signing In..." : "Sign In"}
        </button>
      </form>

      {/* Sign up redirect */}
      <p className="mt-4 text-center">
        Don't have an account?{" "}
        <button
          onClick={onSignupClick}
          className="text-rose-500 hover:text-rose-600"
        >
          Sign up
        </button>
      </p>
    </div>
  );
};

export default LoginForm;

import React, { useState, useEffect, useRef } from "react";
import { User } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function UserMenu({
  isUserMenuOpen,
  toggleUserMenu,
  isLoggedIn,
  storedUser,
  handleLogin,
  handleLogout,
}) {
  const navigate = useNavigate();
  const userMenuRef = useRef(null); // Ref for the user menu
  const [isMenuOpen, setIsMenuOpen] = useState(isUserMenuOpen); // Initialize the menu open state

  useEffect(() => {
    // Update internal state when external prop changes
    setIsMenuOpen(isUserMenuOpen);
  }, [isUserMenuOpen]);

  useEffect(() => {
    // If the menu is open, listen for clicks outside to close it
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsMenuOpen(false); // Close the menu if clicked outside
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts or menu state changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleUserClick = () => {
    if (!isLoggedIn) {
      // If the user is not logged in, navigate to login and close the menu
      navigate("/login");
    } else {
      // If the user is logged in, navigate to the user profile and close the menu
      navigate("/userprofile");
    }
    setIsMenuOpen(false); // Close the menu after navigation
  };

  // Toggle the menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    toggleUserMenu(); // Call the parent function to sync state
  };

  return (
    <div className="relative">
      <button
        className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
        aria-label="User account"
        onClick={handleUserClick}
      >
        <User className="h-6 w-6" />
      </button>

      {isMenuOpen && (
        <div
          ref={userMenuRef} // Attach ref to detect clicks outside
          className="absolute right-0 mt-2 bg-white shadow-lg rounded-lg p-2"
          style={{ marginTop: "10px", width: "max-content" }}
        >
          {isLoggedIn ? (
            <>
              <p className="px-4 py-2 text-gray-700">{storedUser.name}</p>
              <button
                onClick={handleLogout}
                className="w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100 rounded-lg"
              >
                Logout
              </button>
            </>
          ) : (
            <button
              onClick={handleLogin}
              className="w-full px-4 py-2 text-left text-blue-600 hover:bg-gray-100 rounded-lg"
            >
              Login
            </button>
          )}
        </div>
      )}
    </div>
  );
}

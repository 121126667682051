import React, { useState } from "react";
import { FaShoppingCart } from "react-icons/fa"; // You can choose any icon you prefer

export default function OrdersPage() {
  // Sample orders array, set this based on your actual data
  const orders = [];

  return (
    <div
      className="max-w-full sm:max-w-4xl mx-auto px-4 "
      style={{ height: "100vh" }}
    >
      {/* Header with Orders and Logout Button */}
      <div className="flex items-center justify-between mb-6 sm:mb-12 mt-5">
        <h1 className="text-3xl sm:text-4xl font-bold">YOUR ORDERS</h1>
      </div>

      {/* Check if there are any orders */}
      {orders.length === 0 ? (
        <div className="flex flex-col items-center justify-center bg-white p-4 sm:p-6 rounded-lg shadow-sm">
          <FaShoppingCart className="text-gray-500 text-6xl mb-4" />
          <p className="text-lg text-gray-500">You haven't placed any orders</p>
        </div>
      ) : (
        <div className="space-y-6 sm:space-y-12">
          {/* Render order details here if there are orders */}
          {/* Your order components go here */}
        </div>
      )}
    </div>
  );
}
